<template lang="pug">
.container.bg-white.rounded-bottom
  .row
    .col.bg-blue.rounded-top.py-2.mb-4
      .d-flex.justify-content-between
        span.text-white.h3
          font-awesome-icon(icon="list")
          |  Member Class Records Management
  option-filter(
    ref="filter"
    :consultants="consultants"
    v-model="query"
    @search="search"
    @reset="reset"
  )
  b-tabs(v-model="tabIndex" @input="onTabChange" content-class="mt-3")
    b-tab.border-0(title="Member Class Records" active)
      class-records-table(
        v-model="classRecords.pagination"
        ref="table-class-records"
        :data="classRecords.data"
        @load-data="loadData"
      )
    b-tab.border-0(title="Member Classroom")
      classroom-table(
        v-model="classroom.pagination"
        ref="table-classroom"
        :data="classroom.data"
        @load-data="loadData"
      )
</template>

<script>
import OptionFilter from './optionFilter/index.vue';
import ClassRecordsTable from './classRecordsTable/index.vue';
import ClassroomTable from './classroomTable/index.vue';

import WuwowLoadingMixin from '@/components/wuwowLoading/mixin/index.vue';

import api from '@lioshutan/api-package';

export default {
  components: {
    OptionFilter,
    ClassroomTable,
    ClassRecordsTable,
  },

  mixins: [WuwowLoadingMixin],

  data: () => ({
    loading: null,
    token: '',
    query: {
      time: {
        start: null,
        end: null,
      },
      consultants: [],
      unfilled: null,
    },
    consultants: [1, 2],
    tabIndex: 0,
    classRecords: {
      data: null,
      pagination: {
        page: 1,
        perPage: 5,
        sort: null,
      },
    },
    classroom: {
      data: null,
      pagination: {
        page: 1,
        perPage: 5,
        sort: null,
      },
    },
  }),
  computed: {
    tableTarget() {
      return ['classRecords', 'classroom'][this.tabIndex];
    },
  },
  async created() {
    // if (this.$route.query.query) {
    //   console.log(this.$route.query.query);
    //   const {
    //     tab,
    //     query,
    //     pagination,
    //   } = JSON.parse(this.$route.query.query);

    //   this.tableTarget = tab;
    //   this.query = query;
    //   this[this.tableTarget]['pagination'] = pagination;
    // }

    const tag = this.startLoading();
    this.token = this.$store.state.auth.token;
    const response = await api
      .setDNS(process.env.VUE_APP_BASE_API)
      .consultant(this.token)
      .getCenterConsultants();
    this.consultants = response.map((data) => ({
      name: data.englishName,
      id: data.hbConsultantId,
    }));

    await this.search();
    this.endLoading(tag);
  },
  methods: {
    async loadData(pagination) {
      const tag = this.startLoading();
      this[this.tableTarget]['pagination'] = pagination;

      // TODO: 換頁回來要保留在相同搜尋以及頁數上

      const response = await api
        .setDNS(process.env.VUE_APP_BASE_API)
        .classroom(this.token)
        .getCenterConsultantClassroom({
          ...this[this.tableTarget]['pagination'],
          searchType: 'record',
          classTimeStart: this.query.time.start
            ? new Date(this.query.time.start) : null,
          classTimeEnd: this.query.time.end
            ? new Date(this.query.time.end) : null,
          consultantIds: this.query.consultants.map(consultant => consultant.id),
          unfilledAnalyses: this.query.unfilled,
        });

      console.log(response);

      this[this.tableTarget]['data'] = response;
      this.endLoading(tag);
    },
    async onTabChange() {
      this.query = {
        time: {
          start: null,
          end: null,
        },
        consultants: [],
        unfilled: null,
      };
      console.log('on tab change');

      await this.search();
    },
    async search() {
      await this.loadData(this[this.tableTarget]['pagination']);
    },
    reset(query) {
      // this.query = query;
    },
    setUrlQuery() {
      const query = JSON.stringify({
        tab: this.tableTarget,
        query: this.query,
        pagination: this[this.tableTarget]['pagination'],
      });

      this.$router.replace('? query=' + query);
    },
  },
};
</script>

<style lang="scss">
.tab-content {
  border: 0;
}
</style>
