export default {
  messages: {

    en: {
      classroom_title: 'Classroom Title',
      class_type: 'Class Type',
      class_type_1: '1 on 1',
      class_type_2: '1 vs 3',
      class_type_3: 'Demo',
      class_type_5: 'Demo',
      class_type_6: 'Academy Talks',
      material: 'Material',
      vip_a: 'VIP A',
      vip_b: 'VIP B',
      vip_c: 'VIP C',
      video: 'Video',
      pagination_info: 'Displaying {from} to {to} of {total} items',
      no_relevant_data: 'No relevant data',
      vip_analysis: 'VIP Analysis',
      vip_evaluation: 'VIP Evaluation',
      vip_a_feedback: 'VIP A Feedback',
      vip_b_feedback: 'VIP B Feedback',
      vip_c_feedback: 'VIP C Feedback',
      center_member: 'Center Member',
      center_member_class_records: 'Member Class Records',
      center_member_classroom: 'Member Classroom',
      title: 'Title',
      english_name: 'Consultant Name',
      operation: 'Operation',
    },

    tw: {
      classroom_title: '教室名稱',
      class_type: '課程類型',
      class_type_1: '1 on 1 家教班',
      class_type_2: '1 ~ 3人微班級',
      class_type_3: 'Demo',
      class_type_5: 'Demo',
      class_type_6: '百家書苑',
      material: '教材',
      vip_a: '學生 A',
      vip_b: '學生 B',
      vip_c: '學生 C',
      video: '錄影檔',
      pagination_info: '正在顯示第 {from} 項到第 {to} 項，共 {total} 項',
      no_relevant_data: '無資料',
      vip_analysis: 'VIP程度分析',
      vip_evaluation: 'Vip課後評鑑',
      vip_a_feedback: 'VIP A 回饋',
      vip_b_feedback: 'VIP B 回饋',
      vip_c_feedback: 'VIP C 回饋',
      center_member: '語言中心成員',
      center_member_class_records: '成員課程評鑑',
      center_member_classroom: '成員教學課程',
      title: '標題',
      english_name: '顧問名稱',
      operation: '操作',
    },

  },
};
