<template lang="pug">
good-table(
  mode="remote"
  @on-page-change="onPageChange"
  @on-per-page-change="onPerPageChange"
  @on-sort-change="onSortChange"
  :columns="fields"
  :rows="rows"
  :totalRows="totalRows"
  :sort-options="{ enabled: true, multipleColumns: false, }"
  :pagination-options="options"
)
  template(slot="table-row" slot-scope="{ column, row }")
    template(v-if="column.field === 'title'") {{ row.title }}
    template(v-if="column.field === 'classType'")
      template(v-if="row.classType === 5")
        | {{ $t(`class_type_${row.classType}`) }}
        span.badge.badge-danger 10
      template(v-else-if="row.classType === 3")
        | {{ $t(`class_type_${row.classType}`) }}
        span.badge.badge-warning 25
      template(v-else)
        | {{ $t(`class_type_${row.classType}`) }}
    template(v-if="column.field === 'status'")
      template(v-if="row.status === 0") No Show
      template(v-if="row.status === 1") Show
      template(v-if="row.status === 2") Late
      template(v-if="row.status === 3") Tardy
      template(v-if="row.status === 4") Problem
    template(v-if="column.field === 'material'")
      | {{ row.material.title }}
    template(v-if="column.field === 'vipA'")
      vip-field(
        v-if="row.vips && row.vips[0]"
        :data="{ ...row, vip: row.vips[0] }"
      )
    template(v-if="column.field === 'vipAFeedback'")
      vip-feedback(
        v-if="row.vips && row.vips[0]"
        :data="{...row, vip: row.vips[0]}"
      )
    template(v-if="column.field === 'vipB'")
      vip-field(
        v-if="row.vips && row.vips[1]"
        :data="{ ...row, vip: row.vips[1] }"
      )
    template(v-if="column.field === 'vipBFeedback'")
      vip-feedback(
        v-if="row.vips && row.vips[1]"
        :data="{...row, vip: row.vips[1]}"
      )
    template(v-if="column.field === 'vipC'")
      vip-field(
        v-if="row.vips && row.vips[2]"
        :data="{ ...row, vip: row.vips[2] }"
      )
    template(v-if="column.field === 'vipCFeedback'")
      vip-feedback(
        v-if="row.vips && row.vips[2]"
        :data="{...row, vip: row.vips[2]}"
      )
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable as GoodTable } from 'vue-good-table/dist/vue-good-table';
import toSnake from 'snakecase-keys';

import VipField from './vipField/index.vue';
import VipFeedback from './vipFeedbackField/index.vue';

import fields from './fields';
import i18n from './lang';

const DEFAULT_PER_PAGE = 5;

export default {
  components: {
    GoodTable,
    VipField,
    VipFeedback,
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
  i18n,
  data: () => ({
    fields,
    options: {
      enabled: true,
      // mode: 'records',
      mode: 'pages',
      perPage: DEFAULT_PER_PAGE,
      // position: 'top',
      perPageDropdown: [5, 10, 15, 20],
      // dropdownAllowAll: false,
      // setCurrentPage: 2,
      jumpFirstOrLast: true,
      // firstLabel: 'First Page',
      // lastLabel: 'Last Page',
      // nextLabel: 'next',
      // prevLabel: 'prev',
      // rowsPerPageLabel: 'Rows per page',
      // ofLabel: 'of',
      // pageLabel: 'page', // for 'pages' mode
      // allLabel: 'All',
      // infoFn: (params) => `my own page ${params.firstRecordOnPage}`,
    },
    serverParams: {
      page: 1,
      perPage: DEFAULT_PER_PAGE,
      sort: null,
    },
  }),
  computed: {
    rows() {
      if (!this.data) {
        return [];
      }
      return this.data.data.memberClassRecords;
    },
    totalRows() {
      if (!this.data) {
        return 0;
      }
      return this.data.meta.pagination.total;
    },
  },
  methods: {
    toSnake,
    onPageChange(params) {
      this.serverParams.page = params.currentPage;
      this.loadItems();
    },
    onPerPageChange(params) {
      this.serverParams.perPage = params.currentPerPage;
      this.loadItems();
    },
    onSortChange(params) {
      const { field, type } = params[0];
      this.serverParams.sort = `${field}|${type}`;

      if (type === 'none') {
        this.serverParams.sort = null;
      }
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    loadItems() {
      const pagination = Object.assign({}, {
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
        sort: this.serverParams.sort,
      });
      this.$emit('load-data', pagination);
    },
    onReturnListVariant(rating) {
      if (rating >= 80) {
        return 'success';
      } else if (rating >= 60) {
        return 'warning';
      } else {
        return 'danger';
      }
    },
    onCheckSuggest(data) {
      if (data.hbSuggestion ||
        (
          data.suggestionExpressTimeRating &&
          data.suggestionExpressTimeRating !== '0'
        ) ||
        data.suggestionOverallTimeRating ||
        data.suggestionAdviceRating
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.vgt-global-search {
  border: 0;
  background: #fff;
  max-width: 100%;
  .vgt-global-search__input {
    max-width: 65%;
    width: 100%;
  }
  input {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 2px solid #ddd;
    border-radius: 0;
  }
  .input-field {
    border-bottom: 2px solid #ddd;
    margin: 0 0;
    width: 100%;
  }
  .vgt-global-search__actions {
    max-width: 25%;
    width: 100%;
  }
  select {
    -webkit-appearance: none;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    font-size: 14px;
    display: inline-block;
    max-width: 85%;
    width: 100%;
    option {
      color: #999;
    }
  }
}

.vgt-table.bordered {
  border: 0;
  tr.clickable {
    border-bottom: 1px solid #ddd;
    &:hover {
      background-color: #F5F5F5;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
  thead {
    th {
      background: #fff;
    }
  }
  td {
    border-left: 0;
    border-right: 0;
    padding: 20px;
  }
  th {
    border: 0;
  }
  th.vgt-checkbox-col {
    background: #fff;
    input[type="checkbox"] {
      width: 22px;
      height: 22px;
      border: 2px solid #888;
      border-radius: 2px;
      -webkit-appearance: none;
      cursor: pointer;
      position: relative;
      display: inline-block;
      &:checked {
        background-color: #757575;
        border-color: #363636;
        &:after {
          content: '';
          display: block;
          width: 6px;
          height: 12px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          position: absolute;
          top: 0;
          left: 25%;
        }
      }
    }
  }
}

.vgt-wrap__actions-footer, .vgt-wrap__footer {
  border: 0;
}
</style>
