var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.data.vip ? _c('div', [_vm._v(_vm._s(_vm.data.vip.englishName))]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }