export default [
  {
    label: 'Title',
    field: 'title',
    sortable: true,
  },
  {
    label: 'Class Type',
    field: 'classType',
    sortable: false,
  },
  {
    label: 'Material',
    field: 'material',
    sortable: false,
  },
  {
    label: 'Vip A',
    field: 'vipA',
    sortable: false,
  },
  {
    label: 'VIP A Feedback',
    field: 'vipAFeedback',
    sortable: false,
  },
  {
    label: 'VIP B',
    field: 'vipB',
    sortable: false,
  },
  {
    label: 'VIP B Feedback',
    field: 'vipBFeedback',
    sortable: false,
  },
  {
    label: 'VIP C',
    field: 'vipC',
    sortable: false,
  },
  {
    label: 'VIP C Feedback',
    field: 'vipCFeedback',
    sortable: false,
  },
  {
    label: 'Operation',
    field: 'operation',
    sortable: false,
  },
];
