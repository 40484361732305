<template lang="pug">
.row
  .col-md-4
    .form-group
      label Class Start Time
      date-picker.w-100.d-block(
        v-model="query.time.start"
        type="datetime"
        value-type="format"
        @input="change"
      )
      small.form-text.text-muted(
        v-if="false"
      ) GG
  .col-md-4
    .form-group
      label Class End Time
      date-picker.w-100.d-block(
        v-model="query.time.end"
        type="datetime"
        value-type="format"
        @input="change"
      )
      small.form-text.text-muted(
        v-if="false"
      ) GG
  .col-md-4
    .form-group
      label Unfilled Analyses
      select.form-control(v-model="query.unfilled" @input="change")
        option(:value="null" selected) Select option
        option(:value="true") Yes
        option(:value="false") No
      small.form-text.text-muted(
        v-if="false"
      ) GG
  .col-md-4
    .form-group
      label Consultants
      multiselect(
        v-model="query.consultants"
        @input="change"
        :options="consultants"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="true"
        :preserve-search="true"
        placeholder="Pick some"
        label="name"
        track-by="id"
      )
        template(
          slot="selection"
          slot-scope="{ values, search, isOpen }"
        )
          span.multiselect__single(v-if="values.length && !isOpen")
            | {{ values.length }} options selected
      small.form-text.text-muted(
        v-if="false"
      ) GG
  .col-md-4
    .form-group
      label.invisible Action
      div
        button.btn.btn-primary.mr-2(@click="search") Search
        button.btn.btn-default(@click="reset") Reset
      small.form-text.text-muted(
        v-if="false"
      ) GG
</template>

<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
    Multiselect,
  },
  props: {
    consultants: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: Object,
      default: () => ({
        time: {
          start: null,
          end: null,
        },
        consultants: [],
        unfilled: null,
      }),
    },
  },
  data: () => ({
    query: {
      time: {
        start: null,
        end: null,
      },
      consultants: [],
      unfilled: null,
    },
  }),
  watch: {
    // 外界直接修改v-model內的參數，要被監聽到，並且將值重新賦予內部的變數
    value: {
      deep: true,
      handler() {
        this.query = this.value;
      },
    },
  },
  created() {
    this.query = this.value;
  },
  methods: {
    search() {
      this.$emit('search');
    },
    reset() {
      this.query = {
        time: {
          start: null,
          end: null,
        },
        consultants: [],
        unfilled: null,
      };
      this.change();
      this.$emit('reset');
    },
    change() {
      this.$emit('input', this.query);
    },
  },
};
</script>
