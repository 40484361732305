<template lang="pug">
div(v-if="data.vip")
  | {{ data.vip.englishName }}
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      default: () => (null),
    },
  },
  methods: {
  },
};
</script>
