<template lang="pug">
div(v-if="data.vip")
  | {{ data.vip.englishName }}
  template(v-if="data.status === 2")
    span.badge.badge-pill.badge-info Problem Demo Class
  template(v-else-if="data.status === 3")
    span.badge.badge-pill.badge-info Demo Paid
  template(v-else)
    template(
      v-if="\
        data.vip.vipStatus === 1 &&\
        isNotBefore(data.title) &&\
        data.vip.analysisCreatedAt\
      "
    )
      router-link.btn(
        :to="{\
          name: 'center-view-analysis',\
          query: { caId: data.vip.caId, vipId: data.vip.vipId }\
        }"
        :class="(onCheckAnalysisTime(\
          data.vip.classTime,\
          data.vip.analysisCreatedAt\
        ))"
      )
        font-awesome-icon(icon="chart-bar")
      br
      span {{data.vip.analysisCreatedAt}}
    span.badge.badge-pill.badge-danger(
      v-if="data.vip.vipStatus === 0"
    ) No show
    span.badge.badge-pill.badge-warning(
      v-if="data.vip.vipStatus === 2"
    ) Problem
    span.badge.badge-pill.badge-primary(
      v-if="data.vip.vipStatus === 3"
    ) Returned
    span.badge.badge-pill.badge-secondary(
      v-if="data.vip.vipStatus === 4"
    ) Cancelled
</template>

<script>
import moment from 'moment';

export default {
  props: {
    data: {
      type: Object,
      default: () => (null),
    },
  },
  methods: {
    isNotBefore(date) {
      const classDate = moment(date.substring(0, 10)).add(1, 'days');
      const now = moment().format('YYYY-MM-DD');
      return !moment(now).isBefore(classDate);
    },

    onCheckAnalysisTime(classTime, createTime) {
      const deadline = moment(classTime).add(1, 'days');
      const diff = moment(createTime).isBefore(deadline);
      return diff ? 'btn-success' : 'btn-warning';
    },
  },
};
</script>
