<template lang="pug">
v-template(v-if="data.vip")
  router-link(
    v-if="data.vip.vipEvaluation && data.vip.vipEvaluation.consultantRating"
    :to="{\
      name: 'center-view-evaluation',\
      params: { evaluationId: data.vip.vipEvaluation.id }\
    }"
  )
    span.badge.badge-pill.badge-primary Rating:
    b-badge(:variant="onReturnListVariant(data.vip.vipEvaluation.consultantRating)")
      | {{ data.vip.vipEvaluation.consultantRating }}
    span(v-show="onCheckSuggest(data.vip.vipEvaluation)")
      font-awesome-icon(icon="comments" size="lg")
  div(v-else)
    span.badge.badge-pill.badge-danger No rating
</template>

<script>
import { Fragment as VTemplate } from 'vue-fragment';

export default {
  components: {
    VTemplate,
  },
  props: {
    data: {
      type: Object,
      default: () => (null),
    },
  },
  methods: {
    onRedirectEvaluation(vip, hb_name, material_title) {
      // TODO: 切換頁面
      console.log([vip, hb_name, material_title]);
    },
    onCheckSuggest(data) {
      return data.hbSuggestion ||
        (data.suggestionExpressTimeRating && data.suggestionExpressTimeRating !== '0') ||
        data.suggestionOverallTimeRating ||
        data.suggestionAdviceRating;
    },
    onReturnListVariant(rating) {
      if (rating >= 80) {
        return 'success';
      } else if (rating >= 60) {
        return 'info';
      } else if (rating >= 40) {
        return 'warning';
      } else {
        return 'danger';
      }
    },
  },
};
</script>
